import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Molly - Simple Applicant Tracking Software for Startups" />

    <div className="container pt-5">
      <h2 className="mb-5">Privacy Policy</h2>

      <div>
        <div data-custom-class="body">
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="title"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
          </div>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <strong>
                <span data-custom-class="subtitle">
                  Last updated <bdt className="question">August 01, 2020</bdt>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
            <br />
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Thank you for choosing to be part of our community at{" "}
                <bdt className="question">SmashTaps Pvt Ltd</bdt>
                <bdt className="block-component" /> (“
                <bdt className="block-component" />
                <strong>Company</strong>
                <bdt className="statement-end-if-in-editor" />
                ”, “<strong>we</strong>”, “<strong>us</strong>”, or “
                <strong>our</strong>”). We are committed to protecting your
                personal information and your right to privacy. If you have any
                questions or concerns about this privacy notice, or our
                practices with regards to your personal information, please
                contact us at{" "}
                <bdt className="question">hello@smashtaps.com</bdt>.
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                When you <bdt className="block-component" />
                visit our website{" "}
                <bdt className="question">
                  <a
                    href="https://hellomolly.io"
                    target="_blank"
                    data-custom-class="link"
                  >
                    https://hellomolly.io
                  </a>
                </bdt>{" "}
                (the "<strong>Website</strong>"),{" "}
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" /> and more generally, use any
                of our services (the "<strong>Services</strong>", which include
                the{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    Website
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                ), we appreciate that you are trusting us with your personal
                information. We take your privacy very seriously. In this
                privacy notice, we seek to explain to you in the clearest way
                possible what information we collect, how we use it and what
                rights you have in relation to it. We hope you take some time to
                read through it carefully, as it is important. If there are any
                terms in this privacy notice
                <span style={{ color: "rgb(89, 89, 89)" }}>&nbsp;</span>that you
                do not agree with, please discontinue use of our Services
                immediately.
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                This privacy notice applies to all information collected through
                our Services (which, as described above, includes our{" "}
                <bdt className="block-component" />
                Website
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <bdt className="block-component">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </span>
            <span data-custom-class="body_text">
              ), as well as any related services, sales, marketing or events.
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <span data-custom-class="body_text">
                  Please read this privacy notice
                  <span style={{ color: "rgb(89, 89, 89)" }}>&nbsp;</span>
                  carefully as it will help you understand what we do with the
                  information that we collect.
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <br />
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: "19px" }}>
                  <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#infocollect">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                2. HOW DO WE USE YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#infoshare">
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </a>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#whoshare">
                4. WHO WILL YOUR INFORMATION BE SHARED WITH?
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <bdt className="block-component" />
            </span>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </span>
            <bdt className="block-component" />
            <bdt className="block-component" />
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#inforetain">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#infosafe">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#infominors">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                7. DO WE COLLECT INFORMATION FROM MINORS?
              </span>
            </a>
            <bdt className="statement-end-if-in-editor" />
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#privacyrights">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                8. WHAT ARE YOUR PRIVACY RIGHTS?
              </span>
            </a>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#DNT">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#caresidents">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#policyupdates">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </a>
          </p>
          <p style={{ fontSize: "15px" }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </p>
          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <br />
            </span>
          </p>
          <p id="infocollect" style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: "19px" }}>
                  <span data-custom-class="heading_1">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </span>
              </strong>
            </span>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </p>
          <p style={{ fontSize: "15px" }}>
            <strong>
              <span style={{ fontSize: "16px" }}>
                <span data-custom-class="heading_2">
                  <strong>
                    <span data-custom-class="heading_2">
                      <br />
                      Personal information you disclose to us
                    </span>
                  </strong>
                </span>
              </span>
            </strong>
          </p>
          <p style={{ fontSize: "15px" }}>
            <strong>
              <span data-custom-class="body_text">
                <em>
                  <span style={{ fontSize: "15px" }}>In Short: &nbsp;</span>
                </em>
              </span>
            </strong>
            <span data-custom-class="body_text">
              <em>
                <span style={{ fontSize: "15px" }}>
                  We collect information that you provide to us.
                </span>
              </em>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: "15px" }}>
                We collect personal information that you voluntarily provide to
                us when you <bdt className="block-component" />
                register on the <bdt className="block-component" />
                Website, <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <span data-custom-class="body_text">
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the{" "}
                <span data-custom-class="body_text">
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="block-component" />
                    Website
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
                <bdt className="block-component" /> (such as by posting messages
                in our online forums or entering competitions, contests or
                giveaways)
                <bdt className="statement-end-if-in-editor" /> or otherwise when
                you contact us.
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: "15px" }}>
                The personal information that we collect depends on the context
                of your interactions with us and the{" "}
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  Website
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
                , the choices you make and the products and features you use.
                The personal information we collect may include the following:
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <strong>Personal Information Provided by You.</strong> We
                collect <bdt className="forloop-component" />
                <bdt className="question">names</bdt>;{" "}
                <bdt className="forloop-component" />
                <bdt className="question">phone numbers</bdt>;{" "}
                <bdt className="forloop-component" />
                <bdt className="question">email addresses</bdt>;{" "}
                <bdt className="forloop-component" />
                <bdt className="question">mailing addresses</bdt>;{" "}
                <bdt className="forloop-component" />
                and other similar information.
              </span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: "15px" }}>
            <span style={{ fontSize: "15px" }}>
              <strong>
                <span data-custom-class="body_text">Payment Data.&nbsp;</span>
              </strong>
              <span data-custom-class="body_text">
                We may collect data necessary to process your payment if you
                make purchases, such as your payment instrument number (such as
                a credit card number), and the security code associated with
                your payment instrument. All payment data is stored by
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />{" "}
                        <bdt className="question">Paddle</bdt>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="forloop-component" />
                              </span>
                            </span>
                            . You may find their privacy notice link(s) here:
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="forloop-component" />{" "}
                              <bdt className="question">
                                <a
                                  href="https://paddle.com/privacy/"
                                  target="_blank"
                                  data-custom-class="link"
                                >
                                  https://paddle.com/privacy/
                                </a>
                              </bdt>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="forloop-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span data-custom-class="body_text">.</span>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <div>
            <span data-custom-class="body_text">
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
              <span style={{ fontSize: "15px" }}>
                <bdt className="statement-end-if-in-editor">
                  <bdt className="statement-end-if-in-editor" />
                </bdt>
              </span>
            </span>
            <bdt className="block-component" />
          </div>
          <p style={{ fontSize: "15px" }}>
            <strong>
              <span style={{ fontSize: "16px" }}>
                <span data-custom-class="heading_2">
                  <strong>
                    <span data-custom-class="heading_2">
                      <br />
                      Information automatically collected
                    </span>
                  </strong>
                </span>
              </span>
            </strong>
          </p>
          <div>
            <span data-custom-class="body_text">
              <em>
                <strong>In Short: &nbsp;</strong>Some information — such as your
                Internet Protocol (IP) address and/or browser and device
                characteristics — is collected automatically when you visit our{" "}
                <span data-custom-class="body_text">
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      Website
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                .
              </em>
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span data-custom-class="body_text">
              We automatically collect certain information when you visit, use
              or navigate the{" "}
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  Website
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </span>
              . This information does not reveal your specific identity (like
              your name or contact information) but may include device and usage
              information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, device name, country, location, information about who and
              when you use our{" "}
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  Website
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </span>{" "}
              and other technical information. This information is primarily
              needed to maintain the security and operation of our{" "}
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  Website
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </span>
              , and for our internal analytics and reporting purposes.
            </span>
            <bdt className="block-component" />
            <bdt className="block-component" />
            &nbsp;
          </div>
          <div>
            <br />
          </div>
          <div>
            <span data-custom-class="body_text">
              The information we collect includes:
            </span>
            <bdt className="block-component" />
          </div>
          <ul>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <em>Log and Usage Data.</em> Log and usage data is
                    service-related, diagnostic usage and performance
                    information our servers automatically collect when you
                    access or use our{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type and settings and
                    information about your activity in the{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    (such as the date/time stamps associated with your usage,
                    pages and files viewed, searches and other actions you take
                    such as which features you use), device event information
                    (such as system activity, error reports (sometimes called
                    'crash dumps') and hardware settings).
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component" />
          </div>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <font color="#595959">
                  <span style={{ fontSize: "15px" }}>
                    <em>Device Data.&nbsp;</em>We collect device data such as
                    information about your computer, phone, tablet or other
                    device you use to access the&nbsp;
                  </span>
                </font>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    Website
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
                <font color="#595959">
                  <span style={{ fontSize: "15px" }}>
                    . Depending on the device used, this device data may include
                    information such as your IP address (or proxy server),
                    device application identification numbers, location, browser
                    type, hardware model Internet service provider and/or mobile
                    carrier, operating system configuration&nbsp;information.
                  </span>
                </font>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component" />
          </div>
          <ul>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <em>Location Data.</em> We collect information data such as
                    information about your device's location, which can be
                    either precise or imprecise. How much information we collect
                    depends on the type of settings of the device you use to
                    access the{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                    . For example, we may use GPS and other technologies to
                    collect geolocation data that tells us your current location
                    (based on your IP address). You can opt out of allowing us
                    to collect this information either by refusing access to the
                    information or by disabling your Locations settings on your
                    device. Note however, if you choose to opt out, you may not
                    be able to use certain aspects of the Services.
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component" />
            <bdt className="statement-end-if-in-editor" />
            <span data-custom-class="body_text">
              <span style={{ fontSize: "15px" }}>
                <bdt className="statement-end-if-in-editor">
                  <bdt className="statement-end-if-in-editor">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: "15px" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <bdt className="block-component" />
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </bdt>
                </bdt>
              </span>
            </span>
            <bdt className="block-component" />
            <p />
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="infouse" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      2. HOW DO WE USE YOUR INFORMATION?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <em>
                  <strong>In Short: &nbsp;</strong>We process your information
                  for purposes based on legitimate business interests, the
                  fulfillment of our contract with you, compliance with our
                  legal obligations, and/or your consent.
                </em>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                We use personal information collected via our{" "}
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    Website
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>{" "}
                for a variety of business purposes described below. We process
                your personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </span>
              <bdt className="block-component" />
            </p>
            <p style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                We use the information we collect or receive:
              </span>
              <bdt className="block-component" />
            </p>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            To facilitate account creation and logon process.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;If you choose to link your account with us to a
                          third-party account (such as your Google or Facebook
                          account), we use the information you allowed us to
                          collect from those third parties to facilitate account
                          creation and logon process for the performance of the
                          contract. <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To post testimonials.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We post testimonials on our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>{" "}
                      that may contain personal information. Prior to posting a
                      testimonial, we will obtain your consent to use your name
                      and the consent of the testimonial. If you wish to update,
                      or delete your testimonial, please contact us at{" "}
                      <bdt className="block-component" />
                      <bdt className="question">hello@smashtaps.com</bdt>
                      <bdt className="else-block" /> and be sure to include your
                      name, testimonial location, and contact information.
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Request feedback.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may use your information to request feedback and
                      to contact you about your use of our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      .
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To enable user-to-user communications.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may use your information in order to enable
                      user-to-user communications with each user's consent.
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        To
                                                                        manage
                                                                        user
                                                                        accounts
                                                                      </strong>
                                                                      . We may
                                                                      use your
                                                                      information
                                                                      for the
                                                                      purposes
                                                                      of
                                                                      managing
                                                                      our
                                                                      account
                                                                      and
                                                                      keeping it
                                                                      in working
                                                                      order.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To send administrative information to you.&nbsp;
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      We may use your personal information to send you product,
                      service and new feature information and/or information
                      about changes to our terms, conditions, and policies.
                    </span>
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To protect our Services.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may use your information as part of our efforts
                      to keep our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>{" "}
                      safe and secure (for example, for fraud monitoring and
                      prevention).
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To enforce our terms, conditions and policies for
                        business purposes, to comply with legal and regulatory
                        requirements or in connection with our contract.
                      </span>
                    </strong>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        To respond to legal requests and prevent harm.&nbsp;
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      If we receive a subpoena or other legal request, we may
                      need to inspect the data we hold to determine how to
                      respond.
                    </span>
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Fulfill and manage your orders.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may use your information to fulfill and manage
                      your orders, payments, returns, and exchanges made through
                      the{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      .
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Administer prize draws and competitions.&nbsp;
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      We may use your information to administer prize draws and
                      competitions when you elect to participate in our
                      competitions.
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        To
                                                                        deliver
                                                                        and
                                                                        facilitate
                                                                        delivery
                                                                        of
                                                                        services
                                                                        to the
                                                                        user.
                                                                      </strong>{" "}
                                                                      We may use
                                                                      your
                                                                      information
                                                                      to provide
                                                                      you with
                                                                      the
                                                                      requested
                                                                      service.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        To
                                                                        respond
                                                                        to user
                                                                        inquiries/offer
                                                                        support
                                                                        to
                                                                        users.
                                                                      </strong>{" "}
                                                                      We may use
                                                                      your
                                                                      information
                                                                      to respond
                                                                      to your
                                                                      inquiries
                                                                      and solve
                                                                      any
                                                                      potential
                                                                      issues you
                                                                      might have
                                                                      with the
                                                                      use of our
                                                                      Services.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component">
                <bdt className="statement-end-if-in-editor" />
              </bdt>
            </p>
            <p style={{ fontSize: "15px" }}>
              <br />
            </p>
            <p id="infoshare" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>
                    &nbsp;
                  </em>
                  &nbsp;
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We only share information with your consent, to comply
                      with laws, to provide you with services, to protect your
                      rights, or to fulfill business obligations.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We may process or share your data that we hold based on the
                  following legal basis:
                </span>
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Consent:</strong> We may process your data if you
                    have given us specific consent to use your personal
                    information in a specific purpose.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Legitimate Interests:</strong> We may process your
                    data when it is reasonably necessary to achieve our
                    legitimate business interests.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Performance of a Contract:&nbsp;</strong>Where we
                    have entered into a contract with you, we may process your
                    personal information to fulfill the terms of our contract.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Legal Obligations:</strong> We may disclose your
                    information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a
                    judicial proceeding, court order, or legal process, such as
                    in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <strong>Vital Interests:</strong> We may disclose your
                    information where we believe it is necessary to investigate,
                    prevent, or take action regarding potential violations of
                    our policies, suspected fraud, situations involving
                    potential threats to the safety of any person and illegal
                    activities, or as evidence in litigation in which we are
                    involved.
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    More specifically, we may need to process your data or share
                    your personal information in the following situations:
                  </span>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Business Transfers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may share or transfer your information in
                      connection with, or during negotiations of, any merger,
                      sale of company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Vendors, Consultants and Other Third-Party Service
                        Providers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      &nbsp;We may share your data with third-party vendors,
                      service providers, contractors or agents who perform
                      services for us or on our behalf and require access to
                      such information to do that work. Examples include:
                      payment processing, data analysis, email delivery, hosting
                      services, customer service and marketing efforts. We may
                      allow selected third parties to use tracking technology on
                      the{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      , which will enable them to collect data on our behalf
                      about how you interact with our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            Website
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>{" "}
                      over time. This information may be used to, among other
                      things, analyze and track data, determine the popularity
                      of certain content, pages or features, and better
                      understand online activity. Unless described in this
                      notice, we do not share, sell, rent or trade any of your
                      information with third parties for their promotional
                      purposes. <bdt className="block-component" />
                      We have contracts in place with our data processors, which
                      are designed to help safegaurd your personal information.
                      This means that they cannot do anything with your personal
                      information unless we have instructed them to do it. They
                      will also not share your personal information with any
                      organization apart from us. They also commit to protect
                      the data they hold on our behalf and to retain it for the
                      period we instruct.
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </span>
                </span>
              </span>
              <div>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <div>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                      <div>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                        </span>
                        <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                          <br />
                        </p>
                        <p id="whoshare" style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(0, 0, 0)" }}>
                            <strong>
                              <span style={{ fontSize: "19px" }}>
                                <span data-custom-class="heading_1">
                                  4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                                </span>
                              </span>
                            </strong>
                          </span>
                        </p>
                      </div>
                      <div>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <strong>
                            <em>
                              <span style={{ fontSize: "15px" }}>
                                <span data-custom-class="body_text">
                                  In Short:
                                </span>
                              </span>
                              &nbsp;
                            </em>
                            &nbsp;
                          </strong>
                          <span style={{ fontSize: "15px" }}>
                            <em>
                              <span data-custom-class="body_text">
                                We only share information with the following
                                third parties.
                              </span>
                            </em>
                          </span>
                        </span>
                      </div>
                      <div>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>&nbsp;</span>&nbsp;
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              We only share and disclose your information with
                              the following third parties. We have categorized
                              each party so that you may easily understand the
                              purpose of our data collection and processing
                              practices. If we have processed your data based on
                              your consent and you wish to revoke your consent,
                              please contact us using the contact details
                              provided in the section below titled "
                            </span>
                          </span>
                        </span>
                        <a data-custom-class="link" href="#contact">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                          </span>
                        </a>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">".</span>
                          </span>
                        </span>
                        <bdt className="block-component" />
                        <div>
                          <bdt className="block-component" />
                          <div>
                            <bdt className="block-component" />
                            <div>
                              <bdt className="block-component" />
                            </div>
                            <ul>
                              <li>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>Cloud Computing Services</strong>
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="forloop-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="block-component" />
                                          <bdt className="question">
                                            Amazon Web Services (AWS)
                                          </bdt>
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt className="forloop-component" />
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </li>
                            </ul>
                            <div>
                              <bdt className="block-component" />
                            </div>
                            <ul>
                              <li>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>
                                        Communicate and Chat with Users
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="forloop-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="block-component" />
                                          <bdt className="question">Drift</bdt>
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt className="forloop-component" />{" "}
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </li>
                            </ul>
                            <div>
                              <bdt className="block-component" />
                              <div>
                                <bdt className="block-component" />
                                <div>
                                  <bdt className="block-component" />
                                </div>
                                <ul>
                                  <li>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            Functionality and Infrastructure
                                            Optimization
                                          </strong>
                                        </span>
                                      </span>
                                    </span>
                                    <br />
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="forloop-component">
                                          <span data-custom-class="body_text" />
                                        </bdt>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt className="block-component" />
                                              <bdt className="question">
                                                Amazon Web Services
                                              </bdt>
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                      <bdt className="forloop-component" />
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </li>
                                </ul>
                                <div>
                                  <bdt className="block-component" />
                                </div>
                                <ul>
                                  <li>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>Invoice and Billing</strong>
                                        </span>
                                      </span>
                                    </span>
                                    <br />
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="forloop-component">
                                          <span data-custom-class="body_text" />
                                        </bdt>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt className="block-component" />
                                              <bdt className="question">
                                                Paddle
                                              </bdt>
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                      <bdt className="forloop-component" />
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </li>
                                </ul>
                                <div>
                                  <bdt className="block-component" />
                                  <div>
                                    <bdt className="block-component" />
                                    <div>
                                      <bdt className="block-component" />
                                      <div>
                                        <bdt className="block-component" />
                                        <div>
                                          <bdt className="block-component" />
                                        </div>
                                        <ul>
                                          <li>
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Web and Mobile Analytics
                                                  </strong>
                                                </span>
                                              </span>
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <bdt className="forloop-component">
                                                  <span data-custom-class="body_text" />
                                                </bdt>
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt className="block-component" />
                                                      <bdt className="question">
                                                        Google Analytics
                                                      </bdt>
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                              <bdt className="forloop-component" />
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <bdt className="block-component" />
                                          <div>
                                            <bdt className="block-component" />
                                          </div>
                                          <ul>
                                            <li>
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      Website Performance
                                                      Monitoring
                                                    </strong>
                                                  </span>
                                                </span>
                                              </span>
                                              <br />
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <bdt className="forloop-component">
                                                    <span data-custom-class="body_text" />
                                                  </bdt>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <bdt className="block-component" />
                                                        <bdt className="question">
                                                          Rollbar
                                                        </bdt>
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="forloop-component" />
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component" />
                                            <div>
                                              <span data-custom-class="body_text" />
                                              <span data-custom-class="body_text" />
                                              <span data-custom-class="body_text" />
                                              <span data-custom-class="body_text" />
                                              <span data-custom-class="body_text" />
                                              <bdt className="block-component" />
                                              <div>
                                                <bdt className="block-component" />
                                                <div>
                                                  <bdt className="block-component" />
                                                  <div>
                                                    <bdt className="block-component" />
                                                    <div>
                                                      <bdt className="block-component" />
                                                      <div>
                                                        <bdt className="block-component" />
                                                        <div>
                                                          <bdt className="block-component" />
                                                          <div>
                                                            <bdt className="block-component" />
                                                            <div>
                                                              <bdt className="block-component" />
                                                              <div>
                                                                <bdt className="block-component" />
                                                                <div>
                                                                  <bdt className="block-component" />
                                                                  <div>
                                                                    <bdt className="block-component" />
                                                                    <div>
                                                                      <bdt className="block-component" />
                                                                      <div>
                                                                        <bdt className="block-component" />
                                                                        <div>
                                                                          <bdt className="block-component" />
                                                                          <div>
                                                                            <bdt className="block-component" />
                                                                            <div>
                                                                              <bdt className="block-component" />
                                                                              <div>
                                                                                <span data-custom-class="body_text" />
                                                                                <span data-custom-class="body_text" />
                                                                                <span data-custom-class="body_text" />
                                                                                <bdt className="block-component" />
                                                                                <div>
                                                                                  <bdt className="block-component" />
                                                                                  <div>
                                                                                    <bdt className="block-component" />
                                                                                    <div>
                                                                                      <bdt className="block-component" />
                                                                                      <div>
                                                                                        <bdt className="block-component" />
                                                                                        <div>
                                                                                          <bdt className="block-component" />
                                                                                          <div>
                                                                                            <bdt className="block-component" />
                                                                                            <div>
                                                                                              <bdt className="block-component" />
                                                                                              <div>
                                                                                                <bdt className="block-component" />
                                                                                                <div>
                                                                                                  <bdt className="block-component" />
                                                                                                  <div>
                                                                                                    <bdt className="block-component" />
                                                                                                    <div>
                                                                                                      <bdt className="block-component" />
                                                                                                      <div>
                                                                                                        <bdt className="block-component" />
                                                                                                        <div>
                                                                                                          <bdt className="block-component" />
                                                                                                          <div>
                                                                                                            <bdt className="block-component" />
                                                                                                            <div>
                                                                                                              <bdt className="block-component" />
                                                                                                              <div>
                                                                                                                <bdt className="block-component" />
                                                                                                                <div>
                                                                                                                  <span data-custom-class="body_text" />
                                                                                                                  <span data-custom-class="body_text" />
                                                                                                                  <span data-custom-class="body_text" />
                                                                                                                  <bdt className="block-component" />
                                                                                                                  <div>
                                                                                                                    <bdt className="block-component" />
                                                                                                                    <div>
                                                                                                                      <bdt className="block-component" />
                                                                                                                      <div>
                                                                                                                        <bdt className="block-component" />
                                                                                                                        <div>
                                                                                                                          <bdt className="block-component" />
                                                                                                                          <div>
                                                                                                                            <bdt className="block-component" />
                                                                                                                            <div>
                                                                                                                              <bdt className="block-component" />
                                                                                                                              <div>
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <div>
                                                                                                                                  <bdt className="block-component" />
                                                                                                                                  <div>
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                    <div>
                                                                                                                                      <bdt className="block-component" />
                                                                                                                                      <div>
                                                                                                                                        <bdt className="block-component" />
                                                                                                                                        <div>
                                                                                                                                          <bdt className="block-component" />
                                                                                                                                          <div>
                                                                                                                                            <bdt className="block-component" />
                                                                                                                                            <div>
                                                                                                                                              <bdt className="block-component" />
                                                                                                                                              <div>
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                <div>
                                                                                                                                                  <bdt className="block-component" />
                                                                                                                                                  <div>
                                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <bdt className="block-component">
                                                                                                                                                                <span data-custom-class="heading_1" />
                                                                                                                                                              </bdt>
                                                                                                                                                            </span>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              <bdt className="block-component" />
                                                                                                                                                              <bdt className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                              </span>
                                                                                                                                                            </bdt>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                    <p />
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <br />
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      id="inforetain"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              5.
                                                                                                                                                              HOW
                                                                                                                                                              LONG
                                                                                                                                                              DO
                                                                                                                                                              WE
                                                                                                                                                              KEEP
                                                                                                                                                              YOUR
                                                                                                                                                              INFORMATION?
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <em>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                In
                                                                                                                                                                Short:
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </em>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <em>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              We
                                                                                                                                                              keep
                                                                                                                                                              your
                                                                                                                                                              information
                                                                                                                                                              for
                                                                                                                                                              as
                                                                                                                                                              long
                                                                                                                                                              as
                                                                                                                                                              necessary
                                                                                                                                                              to
                                                                                                                                                              fulfill
                                                                                                                                                              the
                                                                                                                                                              purposes
                                                                                                                                                              outlined
                                                                                                                                                              in
                                                                                                                                                              this
                                                                                                                                                              privacy
                                                                                                                                                              notice
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                &nbsp;
                                                                                                                                                              </span>
                                                                                                                                                              unless
                                                                                                                                                              otherwise
                                                                                                                                                              required
                                                                                                                                                              by
                                                                                                                                                              law.
                                                                                                                                                            </span>
                                                                                                                                                          </em>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            We
                                                                                                                                                            will
                                                                                                                                                            only
                                                                                                                                                            keep
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            for
                                                                                                                                                            as
                                                                                                                                                            long
                                                                                                                                                            as
                                                                                                                                                            it
                                                                                                                                                            is
                                                                                                                                                            necessary
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            purposes
                                                                                                                                                            set
                                                                                                                                                            out
                                                                                                                                                            in
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice,
                                                                                                                                                            unless
                                                                                                                                                            a
                                                                                                                                                            longer
                                                                                                                                                            retention
                                                                                                                                                            period
                                                                                                                                                            is
                                                                                                                                                            required
                                                                                                                                                            or
                                                                                                                                                            permitted
                                                                                                                                                            by
                                                                                                                                                            law
                                                                                                                                                            (such
                                                                                                                                                            as
                                                                                                                                                            tax,
                                                                                                                                                            accounting
                                                                                                                                                            or
                                                                                                                                                            other
                                                                                                                                                            legal
                                                                                                                                                            requirements).
                                                                                                                                                            No
                                                                                                                                                            purpose
                                                                                                                                                            in
                                                                                                                                                            this
                                                                                                                                                            notice
                                                                                                                                                            will
                                                                                                                                                            require
                                                                                                                                                            us
                                                                                                                                                            keeping
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            for
                                                                                                                                                            longer
                                                                                                                                                            than{" "}
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        <bdt className="question">
                                                                                                                                                          three
                                                                                                                                                          (3)
                                                                                                                                                        </bdt>{" "}
                                                                                                                                                        months
                                                                                                                                                        past
                                                                                                                                                        the
                                                                                                                                                        termination
                                                                                                                                                        of
                                                                                                                                                        the
                                                                                                                                                        user's
                                                                                                                                                        account
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                      </span>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="else-block" />
                                                                                                                                                            .
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          When
                                                                                                                                                          we
                                                                                                                                                          have
                                                                                                                                                          no
                                                                                                                                                          ongoing
                                                                                                                                                          legitimate
                                                                                                                                                          business
                                                                                                                                                          need
                                                                                                                                                          to
                                                                                                                                                          process
                                                                                                                                                          your
                                                                                                                                                          personal
                                                                                                                                                          information,
                                                                                                                                                          we
                                                                                                                                                          will
                                                                                                                                                          either
                                                                                                                                                          delete
                                                                                                                                                          or
                                                                                                                                                          anonymize
                                                                                                                                                          such
                                                                                                                                                          information,
                                                                                                                                                          or,
                                                                                                                                                          if
                                                                                                                                                          this
                                                                                                                                                          is
                                                                                                                                                          not
                                                                                                                                                          possible
                                                                                                                                                          (for
                                                                                                                                                          example,
                                                                                                                                                          because
                                                                                                                                                          your
                                                                                                                                                          personal
                                                                                                                                                          information
                                                                                                                                                          has
                                                                                                                                                          been
                                                                                                                                                          stored
                                                                                                                                                          in
                                                                                                                                                          backup
                                                                                                                                                          archives),
                                                                                                                                                          then
                                                                                                                                                          we
                                                                                                                                                          will
                                                                                                                                                          securely
                                                                                                                                                          store
                                                                                                                                                          your
                                                                                                                                                          personal
                                                                                                                                                          information
                                                                                                                                                          and
                                                                                                                                                          isolate
                                                                                                                                                          it
                                                                                                                                                          from
                                                                                                                                                          any
                                                                                                                                                          further
                                                                                                                                                          processing
                                                                                                                                                          until
                                                                                                                                                          deletion
                                                                                                                                                          is
                                                                                                                                                          possible.
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <div
                                                                                                                                                      style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </div>
                                                                                                                                                    <p
                                                                                                                                                      id="infosafe"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              6.
                                                                                                                                                              HOW
                                                                                                                                                              DO
                                                                                                                                                              WE
                                                                                                                                                              KEEP
                                                                                                                                                              YOUR
                                                                                                                                                              INFORMATION
                                                                                                                                                              SAFE?
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <em>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                In
                                                                                                                                                                Short:
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </em>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <em>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              We
                                                                                                                                                              aim
                                                                                                                                                              to
                                                                                                                                                              protect
                                                                                                                                                              your
                                                                                                                                                              personal
                                                                                                                                                              information
                                                                                                                                                              through
                                                                                                                                                              a
                                                                                                                                                              system
                                                                                                                                                              of
                                                                                                                                                              organizational
                                                                                                                                                              and
                                                                                                                                                              technical
                                                                                                                                                              security
                                                                                                                                                              measures.
                                                                                                                                                            </span>
                                                                                                                                                          </em>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          We
                                                                                                                                                          have
                                                                                                                                                          implemented
                                                                                                                                                          appropriate
                                                                                                                                                          technical
                                                                                                                                                          and
                                                                                                                                                          organizational
                                                                                                                                                          security
                                                                                                                                                          measures
                                                                                                                                                          designed
                                                                                                                                                          to
                                                                                                                                                          protect
                                                                                                                                                          the
                                                                                                                                                          security
                                                                                                                                                          of
                                                                                                                                                          any
                                                                                                                                                          personal
                                                                                                                                                          information
                                                                                                                                                          we
                                                                                                                                                          process.
                                                                                                                                                          However,
                                                                                                                                                          despite
                                                                                                                                                          our
                                                                                                                                                          safeguards
                                                                                                                                                          and
                                                                                                                                                          efforts
                                                                                                                                                          to
                                                                                                                                                          secure
                                                                                                                                                          your
                                                                                                                                                          information,
                                                                                                                                                          no
                                                                                                                                                          electronic
                                                                                                                                                          transmission
                                                                                                                                                          over
                                                                                                                                                          the
                                                                                                                                                          Internet
                                                                                                                                                          or
                                                                                                                                                          information
                                                                                                                                                          storage
                                                                                                                                                          technology
                                                                                                                                                          can
                                                                                                                                                          be
                                                                                                                                                          guaranteed
                                                                                                                                                          to
                                                                                                                                                          be
                                                                                                                                                          100%
                                                                                                                                                          secure,
                                                                                                                                                          so
                                                                                                                                                          we
                                                                                                                                                          cannot
                                                                                                                                                          promise
                                                                                                                                                          or
                                                                                                                                                          guarantee
                                                                                                                                                          that
                                                                                                                                                          hackers,
                                                                                                                                                          cybercriminals,
                                                                                                                                                          or
                                                                                                                                                          other
                                                                                                                                                          unauthorized
                                                                                                                                                          third
                                                                                                                                                          parties
                                                                                                                                                          will
                                                                                                                                                          not
                                                                                                                                                          be
                                                                                                                                                          able
                                                                                                                                                          to
                                                                                                                                                          defeat
                                                                                                                                                          our
                                                                                                                                                          security,
                                                                                                                                                          and
                                                                                                                                                          improperly
                                                                                                                                                          collect,
                                                                                                                                                          access,
                                                                                                                                                          steal,
                                                                                                                                                          or
                                                                                                                                                          modify
                                                                                                                                                          your
                                                                                                                                                          information.
                                                                                                                                                          Although
                                                                                                                                                          we
                                                                                                                                                          will
                                                                                                                                                          do
                                                                                                                                                          our
                                                                                                                                                          best
                                                                                                                                                          to
                                                                                                                                                          protect
                                                                                                                                                          your
                                                                                                                                                          personal
                                                                                                                                                          information,
                                                                                                                                                          transmission
                                                                                                                                                          of
                                                                                                                                                          personal
                                                                                                                                                          information
                                                                                                                                                          to
                                                                                                                                                          and
                                                                                                                                                          from
                                                                                                                                                          our{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      Website
                                                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>{" "}
                                                                                                                                                          is
                                                                                                                                                          at
                                                                                                                                                          your
                                                                                                                                                          own
                                                                                                                                                          risk.
                                                                                                                                                          You
                                                                                                                                                          should
                                                                                                                                                          only
                                                                                                                                                          access
                                                                                                                                                          the{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      Website
                                                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>{" "}
                                                                                                                                                          within
                                                                                                                                                          a
                                                                                                                                                          secure
                                                                                                                                                          environment.
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                      </span>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <br />
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      id="infominors"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              7.
                                                                                                                                                              DO
                                                                                                                                                              WE
                                                                                                                                                              COLLECT
                                                                                                                                                              INFORMATION
                                                                                                                                                              FROM
                                                                                                                                                              MINORS?
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <em>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                In
                                                                                                                                                                Short:
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </em>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <em>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              We
                                                                                                                                                              do
                                                                                                                                                              not
                                                                                                                                                              knowingly
                                                                                                                                                              collect
                                                                                                                                                              data
                                                                                                                                                              from
                                                                                                                                                              or
                                                                                                                                                              market
                                                                                                                                                              to
                                                                                                                                                              children
                                                                                                                                                              under
                                                                                                                                                              18
                                                                                                                                                              years
                                                                                                                                                              of
                                                                                                                                                              age.
                                                                                                                                                            </span>
                                                                                                                                                          </em>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          We
                                                                                                                                                          do
                                                                                                                                                          not
                                                                                                                                                          knowingly
                                                                                                                                                          solicit
                                                                                                                                                          data
                                                                                                                                                          from
                                                                                                                                                          or
                                                                                                                                                          market
                                                                                                                                                          to
                                                                                                                                                          children
                                                                                                                                                          under
                                                                                                                                                          18
                                                                                                                                                          years
                                                                                                                                                          of
                                                                                                                                                          age.
                                                                                                                                                          By
                                                                                                                                                          using
                                                                                                                                                          the{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      Website
                                                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          ,
                                                                                                                                                          you
                                                                                                                                                          represent
                                                                                                                                                          that
                                                                                                                                                          you
                                                                                                                                                          are
                                                                                                                                                          at
                                                                                                                                                          least
                                                                                                                                                          18
                                                                                                                                                          or
                                                                                                                                                          that
                                                                                                                                                          you
                                                                                                                                                          are
                                                                                                                                                          the
                                                                                                                                                          parent
                                                                                                                                                          or
                                                                                                                                                          guardian
                                                                                                                                                          of
                                                                                                                                                          such
                                                                                                                                                          a
                                                                                                                                                          minor
                                                                                                                                                          and
                                                                                                                                                          consent
                                                                                                                                                          to
                                                                                                                                                          such
                                                                                                                                                          minor
                                                                                                                                                          dependent’s
                                                                                                                                                          use
                                                                                                                                                          of
                                                                                                                                                          the{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      Website
                                                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          .
                                                                                                                                                          If
                                                                                                                                                          we
                                                                                                                                                          learn
                                                                                                                                                          that
                                                                                                                                                          personal
                                                                                                                                                          information
                                                                                                                                                          from
                                                                                                                                                          users
                                                                                                                                                          less
                                                                                                                                                          than
                                                                                                                                                          18
                                                                                                                                                          years
                                                                                                                                                          of
                                                                                                                                                          age
                                                                                                                                                          has
                                                                                                                                                          been
                                                                                                                                                          collected,
                                                                                                                                                          we
                                                                                                                                                          will
                                                                                                                                                          deactivate
                                                                                                                                                          the
                                                                                                                                                          account
                                                                                                                                                          and
                                                                                                                                                          take
                                                                                                                                                          reasonable
                                                                                                                                                          measures
                                                                                                                                                          to
                                                                                                                                                          promptly
                                                                                                                                                          delete
                                                                                                                                                          such
                                                                                                                                                          data
                                                                                                                                                          from
                                                                                                                                                          our
                                                                                                                                                          records.
                                                                                                                                                          If
                                                                                                                                                          you
                                                                                                                                                          become
                                                                                                                                                          aware
                                                                                                                                                          of
                                                                                                                                                          any
                                                                                                                                                          data
                                                                                                                                                          we
                                                                                                                                                          may
                                                                                                                                                          have
                                                                                                                                                          collected
                                                                                                                                                          from
                                                                                                                                                          children
                                                                                                                                                          under
                                                                                                                                                          age
                                                                                                                                                          18,
                                                                                                                                                          please
                                                                                                                                                          contact
                                                                                                                                                          us
                                                                                                                                                          at{" "}
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                          <bdt className="question">
                                                                                                                                                            hello@smashtaps.com
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="else-block" />
                                                                                                                                                          .
                                                                                                                                                        </span>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <br />
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      id="privacyrights"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              8.
                                                                                                                                                              WHAT
                                                                                                                                                              ARE
                                                                                                                                                              YOUR
                                                                                                                                                              PRIVACY
                                                                                                                                                              RIGHTS?
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <em>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                In
                                                                                                                                                                Short:
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </em>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <em>
                                                                                                                                                              <bdt className="block-component" />
                                                                                                                                                              In
                                                                                                                                                              some
                                                                                                                                                              regions,
                                                                                                                                                              such
                                                                                                                                                              as
                                                                                                                                                              the
                                                                                                                                                              European
                                                                                                                                                              Economic
                                                                                                                                                              Area,
                                                                                                                                                              you
                                                                                                                                                              have
                                                                                                                                                              rights
                                                                                                                                                              that
                                                                                                                                                              allow
                                                                                                                                                              you
                                                                                                                                                              greater
                                                                                                                                                              access
                                                                                                                                                              to
                                                                                                                                                              and
                                                                                                                                                              control
                                                                                                                                                              over
                                                                                                                                                              your
                                                                                                                                                              personal
                                                                                                                                                              information.{" "}
                                                                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                                                                              You
                                                                                                                                                              may
                                                                                                                                                              review,
                                                                                                                                                              change,
                                                                                                                                                              or
                                                                                                                                                              terminate
                                                                                                                                                              your
                                                                                                                                                              account
                                                                                                                                                              at
                                                                                                                                                              any
                                                                                                                                                              time.
                                                                                                                                                            </em>
                                                                                                                                                          </span>
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            In
                                                                                                                                                            some
                                                                                                                                                            regions
                                                                                                                                                            (like
                                                                                                                                                            the
                                                                                                                                                            European
                                                                                                                                                            Economic
                                                                                                                                                            Area),
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            certain
                                                                                                                                                            rights
                                                                                                                                                            under
                                                                                                                                                            applicable
                                                                                                                                                            data
                                                                                                                                                            protection
                                                                                                                                                            laws.
                                                                                                                                                            These
                                                                                                                                                            may
                                                                                                                                                            include
                                                                                                                                                            the
                                                                                                                                                            right
                                                                                                                                                            (i)
                                                                                                                                                            to
                                                                                                                                                            request
                                                                                                                                                            access
                                                                                                                                                            and
                                                                                                                                                            obtain
                                                                                                                                                            a
                                                                                                                                                            copy
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            (ii)
                                                                                                                                                            to
                                                                                                                                                            request
                                                                                                                                                            rectification
                                                                                                                                                            or
                                                                                                                                                            erasure;
                                                                                                                                                            (iii)
                                                                                                                                                            to
                                                                                                                                                            restrict
                                                                                                                                                            the
                                                                                                                                                            processing
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information;
                                                                                                                                                            and
                                                                                                                                                            (iv)
                                                                                                                                                            if
                                                                                                                                                            applicable,
                                                                                                                                                            to
                                                                                                                                                            data
                                                                                                                                                            portability.
                                                                                                                                                            In
                                                                                                                                                            certain
                                                                                                                                                            circumstances,
                                                                                                                                                            you
                                                                                                                                                            may
                                                                                                                                                            also
                                                                                                                                                            have
                                                                                                                                                            the
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            object
                                                                                                                                                            to
                                                                                                                                                            the
                                                                                                                                                            processing
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information.
                                                                                                                                                            To
                                                                                                                                                            make
                                                                                                                                                            such
                                                                                                                                                            a
                                                                                                                                                            request,
                                                                                                                                                            please
                                                                                                                                                            use
                                                                                                                                                            the
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                        &nbsp;
                                                                                                                                                      </span>
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <a
                                                                                                                                                          data-custom-class="link"
                                                                                                                                                          href="#contact"
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(48, 48, 241)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              contact
                                                                                                                                                              details
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </a>
                                                                                                                                                      </span>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            &nbsp;provided
                                                                                                                                                            below.
                                                                                                                                                            We
                                                                                                                                                            will
                                                                                                                                                            consider
                                                                                                                                                            and
                                                                                                                                                            act
                                                                                                                                                            upon
                                                                                                                                                            any
                                                                                                                                                            request
                                                                                                                                                            in
                                                                                                                                                            accordance
                                                                                                                                                            with
                                                                                                                                                            applicable
                                                                                                                                                            data
                                                                                                                                                            protection
                                                                                                                                                            laws.
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            If
                                                                                                                                                            we
                                                                                                                                                            are
                                                                                                                                                            relying
                                                                                                                                                            on
                                                                                                                                                            your
                                                                                                                                                            consent
                                                                                                                                                            to
                                                                                                                                                            process
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            the
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            withdraw
                                                                                                                                                            your
                                                                                                                                                            consent
                                                                                                                                                            at
                                                                                                                                                            any
                                                                                                                                                            time.
                                                                                                                                                            Please
                                                                                                                                                            note
                                                                                                                                                            however
                                                                                                                                                            that
                                                                                                                                                            this
                                                                                                                                                            will
                                                                                                                                                            not
                                                                                                                                                            affect
                                                                                                                                                            the
                                                                                                                                                            lawfulness
                                                                                                                                                            of
                                                                                                                                                            the
                                                                                                                                                            processing
                                                                                                                                                            before
                                                                                                                                                            its
                                                                                                                                                            withdrawal,
                                                                                                                                                            nor
                                                                                                                                                            will
                                                                                                                                                            it
                                                                                                                                                            affect
                                                                                                                                                            the
                                                                                                                                                            processing
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            conducted
                                                                                                                                                            in
                                                                                                                                                            reliance
                                                                                                                                                            on
                                                                                                                                                            lawful
                                                                                                                                                            processing
                                                                                                                                                            grounds
                                                                                                                                                            other
                                                                                                                                                            than
                                                                                                                                                            consent.
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize:
                                                                                                                                                                    "15px",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            resident
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            European
                                                                                                                                                            Economic
                                                                                                                                                            Area
                                                                                                                                                            and
                                                                                                                                                            you
                                                                                                                                                            believe
                                                                                                                                                            we
                                                                                                                                                            are
                                                                                                                                                            unlawfully
                                                                                                                                                            processing
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            you
                                                                                                                                                            also
                                                                                                                                                            have
                                                                                                                                                            the
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            complain
                                                                                                                                                            to
                                                                                                                                                            your
                                                                                                                                                            local
                                                                                                                                                            data
                                                                                                                                                            protection
                                                                                                                                                            supervisory
                                                                                                                                                            authority.
                                                                                                                                                            You
                                                                                                                                                            can
                                                                                                                                                            find
                                                                                                                                                            their
                                                                                                                                                            contact
                                                                                                                                                            details
                                                                                                                                                            here:
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              &nbsp;
                                                                                                                                                            </span>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(48, 48, 241)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <a
                                                                                                                                                                  data-custom-class="link"
                                                                                                                                                                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                                                                                                  rel="noopener noreferrer"
                                                                                                                                                                  target="_blank"
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize:
                                                                                                                                                                        "15px",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                                                                                                  </span>
                                                                                                                                                                </a>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(48, 48, 241)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            .
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            resident
                                                                                                                                                            in
                                                                                                                                                            Switzerland,
                                                                                                                                                            the
                                                                                                                                                            contact
                                                                                                                                                            details
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            data
                                                                                                                                                            protection
                                                                                                                                                            authorities
                                                                                                                                                            are
                                                                                                                                                            available
                                                                                                                                                            here:{" "}
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(48, 48, 241)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <a
                                                                                                                                                                    data-custom-class="link"
                                                                                                                                                                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                                                                                                    rel="noopener noreferrer"
                                                                                                                                                                    target="_blank"
                                                                                                                                                                  >
                                                                                                                                                                    https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                                                                                                  </a>
                                                                                                                                                                  .
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="block-component">
                                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            questions
                                                                                                                                                            or
                                                                                                                                                            comments
                                                                                                                                                            about
                                                                                                                                                            your
                                                                                                                                                            privacy
                                                                                                                                                            rights,
                                                                                                                                                            you
                                                                                                                                                            may
                                                                                                                                                            email
                                                                                                                                                            us
                                                                                                                                                            at{" "}
                                                                                                                                                            <bdt className="question">
                                                                                                                                                              hello@smashtaps.com
                                                                                                                                                            </bdt>
                                                                                                                                                            .
                                                                                                                                                          </span>
                                                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <div
                                                                                                                                                      style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.1",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "16px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(0, 0, 0)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <strong>
                                                                                                                                                            <span data-custom-class="heading_2">
                                                                                                                                                              Account
                                                                                                                                                              Information
                                                                                                                                                            </span>
                                                                                                                                                          </strong>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </div>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            would
                                                                                                                                                            at
                                                                                                                                                            any
                                                                                                                                                            time
                                                                                                                                                            like
                                                                                                                                                            to
                                                                                                                                                            review
                                                                                                                                                            or
                                                                                                                                                            change
                                                                                                                                                            the
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            your
                                                                                                                                                            account
                                                                                                                                                            or
                                                                                                                                                            terminate
                                                                                                                                                            your
                                                                                                                                                            account,
                                                                                                                                                            you
                                                                                                                                                            can:
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      &nbsp;
                                                                                                                                                      &nbsp;
                                                                                                                                                      ■
                                                                                                                                                      &nbsp;
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="question">
                                                                                                                                                              Log
                                                                                                                                                              in
                                                                                                                                                              to
                                                                                                                                                              your
                                                                                                                                                              account
                                                                                                                                                              settings
                                                                                                                                                              and
                                                                                                                                                              update
                                                                                                                                                              your
                                                                                                                                                              user
                                                                                                                                                              account.
                                                                                                                                                            </bdt>{" "}
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      &nbsp;
                                                                                                                                                      &nbsp;
                                                                                                                                                      ■
                                                                                                                                                      &nbsp;
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="question">
                                                                                                                                                              Contact
                                                                                                                                                              us
                                                                                                                                                              using
                                                                                                                                                              the
                                                                                                                                                              contact
                                                                                                                                                              information
                                                                                                                                                              provided.
                                                                                                                                                            </bdt>{" "}
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            Upon
                                                                                                                                                            your
                                                                                                                                                            request
                                                                                                                                                            to
                                                                                                                                                            terminate
                                                                                                                                                            your
                                                                                                                                                            account,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            deactivate
                                                                                                                                                            or
                                                                                                                                                            delete
                                                                                                                                                            your
                                                                                                                                                            account
                                                                                                                                                            and
                                                                                                                                                            information
                                                                                                                                                            from
                                                                                                                                                            our
                                                                                                                                                            active
                                                                                                                                                            databases.
                                                                                                                                                            However,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            retain
                                                                                                                                                            some
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            our
                                                                                                                                                            files
                                                                                                                                                            to
                                                                                                                                                            prevent
                                                                                                                                                            fraud,
                                                                                                                                                            troubleshoot
                                                                                                                                                            problems,
                                                                                                                                                            assist
                                                                                                                                                            with
                                                                                                                                                            any
                                                                                                                                                            investigations,
                                                                                                                                                            enforce
                                                                                                                                                            our
                                                                                                                                                            Terms
                                                                                                                                                            of
                                                                                                                                                            Use
                                                                                                                                                            and/or
                                                                                                                                                            comply
                                                                                                                                                            with
                                                                                                                                                            applicable
                                                                                                                                                            legal
                                                                                                                                                            requirements.
                                                                                                                                                          </span>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <strong>
                                                                                                                                                            <u>
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                Opting
                                                                                                                                                                out
                                                                                                                                                                of
                                                                                                                                                                email
                                                                                                                                                                marketing:
                                                                                                                                                              </span>
                                                                                                                                                            </u>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </strong>
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            You
                                                                                                                                                            can
                                                                                                                                                            unsubscribe
                                                                                                                                                            from
                                                                                                                                                            our
                                                                                                                                                            marketing
                                                                                                                                                            email
                                                                                                                                                            list
                                                                                                                                                            at
                                                                                                                                                            any
                                                                                                                                                            time
                                                                                                                                                            by
                                                                                                                                                            clicking
                                                                                                                                                            on
                                                                                                                                                            the
                                                                                                                                                            unsubscribe
                                                                                                                                                            link
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            emails
                                                                                                                                                            that
                                                                                                                                                            we
                                                                                                                                                            send
                                                                                                                                                            or
                                                                                                                                                            by
                                                                                                                                                            contacting
                                                                                                                                                            us
                                                                                                                                                            using
                                                                                                                                                            the
                                                                                                                                                            details
                                                                                                                                                            provided
                                                                                                                                                            below.
                                                                                                                                                            You
                                                                                                                                                            will
                                                                                                                                                            then
                                                                                                                                                            be
                                                                                                                                                            removed
                                                                                                                                                            from
                                                                                                                                                            the
                                                                                                                                                            marketing
                                                                                                                                                            email
                                                                                                                                                            list
                                                                                                                                                            –
                                                                                                                                                            however,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            still
                                                                                                                                                            communicate
                                                                                                                                                            with
                                                                                                                                                            you,
                                                                                                                                                            for
                                                                                                                                                            example
                                                                                                                                                            to
                                                                                                                                                            send
                                                                                                                                                            you
                                                                                                                                                            service-related
                                                                                                                                                            emails
                                                                                                                                                            that
                                                                                                                                                            are
                                                                                                                                                            necessary
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            administration
                                                                                                                                                            and
                                                                                                                                                            use
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            account,
                                                                                                                                                            to
                                                                                                                                                            respond
                                                                                                                                                            to
                                                                                                                                                            service
                                                                                                                                                            requests,
                                                                                                                                                            or
                                                                                                                                                            for
                                                                                                                                                            other
                                                                                                                                                            non-marketing
                                                                                                                                                            purposes.
                                                                                                                                                            To
                                                                                                                                                            otherwise
                                                                                                                                                            opt-out,
                                                                                                                                                            you
                                                                                                                                                            may:
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      &nbsp;
                                                                                                                                                      &nbsp;
                                                                                                                                                      ■{" "}
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        &nbsp;
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="question">
                                                                                                                                                              Access
                                                                                                                                                              your
                                                                                                                                                              account
                                                                                                                                                              settings
                                                                                                                                                              and
                                                                                                                                                              update
                                                                                                                                                              your
                                                                                                                                                              preferences.
                                                                                                                                                            </bdt>{" "}
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      &nbsp;
                                                                                                                                                      &nbsp;
                                                                                                                                                      ■{" "}
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        &nbsp;
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="question">
                                                                                                                                                              Contact
                                                                                                                                                              us
                                                                                                                                                              using
                                                                                                                                                              the
                                                                                                                                                              contact
                                                                                                                                                              information
                                                                                                                                                              provided.
                                                                                                                                                            </bdt>{" "}
                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                      "15px",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize:
                                                                                                                                                                        "15px",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      id="DNT"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              9.
                                                                                                                                                              CONTROLS
                                                                                                                                                              FOR
                                                                                                                                                              DO-NOT-TRACK
                                                                                                                                                              FEATURES
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            Most
                                                                                                                                                            web
                                                                                                                                                            browsers
                                                                                                                                                            and
                                                                                                                                                            some
                                                                                                                                                            mobile
                                                                                                                                                            operating
                                                                                                                                                            systems
                                                                                                                                                            and
                                                                                                                                                            mobile
                                                                                                                                                            applications
                                                                                                                                                            include
                                                                                                                                                            a
                                                                                                                                                            Do-Not-Track
                                                                                                                                                            (“DNT”)
                                                                                                                                                            feature
                                                                                                                                                            or
                                                                                                                                                            setting
                                                                                                                                                            you
                                                                                                                                                            can
                                                                                                                                                            activate
                                                                                                                                                            to
                                                                                                                                                            signal
                                                                                                                                                            your
                                                                                                                                                            privacy
                                                                                                                                                            preference
                                                                                                                                                            not
                                                                                                                                                            to
                                                                                                                                                            have
                                                                                                                                                            data
                                                                                                                                                            about
                                                                                                                                                            your
                                                                                                                                                            online
                                                                                                                                                            browsing
                                                                                                                                                            activities
                                                                                                                                                            monitored
                                                                                                                                                            and
                                                                                                                                                            collected.
                                                                                                                                                            At
                                                                                                                                                            this
                                                                                                                                                            stage,
                                                                                                                                                            no
                                                                                                                                                            uniform
                                                                                                                                                            technology
                                                                                                                                                            standard
                                                                                                                                                            for
                                                                                                                                                            recognizing
                                                                                                                                                            and
                                                                                                                                                            implementing
                                                                                                                                                            DNT
                                                                                                                                                            signals
                                                                                                                                                            has
                                                                                                                                                            been
                                                                                                                                                            finalized.
                                                                                                                                                            As
                                                                                                                                                            such,
                                                                                                                                                            we
                                                                                                                                                            do
                                                                                                                                                            not
                                                                                                                                                            currently
                                                                                                                                                            respond
                                                                                                                                                            to
                                                                                                                                                            DNT
                                                                                                                                                            browser
                                                                                                                                                            signals
                                                                                                                                                            or
                                                                                                                                                            any
                                                                                                                                                            other
                                                                                                                                                            mechanism
                                                                                                                                                            that
                                                                                                                                                            automatically
                                                                                                                                                            communicates
                                                                                                                                                            your
                                                                                                                                                            choice
                                                                                                                                                            not
                                                                                                                                                            to
                                                                                                                                                            be
                                                                                                                                                            tracked
                                                                                                                                                            online.
                                                                                                                                                            If
                                                                                                                                                            a
                                                                                                                                                            standard
                                                                                                                                                            for
                                                                                                                                                            online
                                                                                                                                                            tracking
                                                                                                                                                            is
                                                                                                                                                            adopted
                                                                                                                                                            that
                                                                                                                                                            we
                                                                                                                                                            must
                                                                                                                                                            follow
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            future,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            inform
                                                                                                                                                            you
                                                                                                                                                            about
                                                                                                                                                            that
                                                                                                                                                            practice
                                                                                                                                                            in
                                                                                                                                                            a
                                                                                                                                                            revised
                                                                                                                                                            version
                                                                                                                                                            of
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice.
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                        lineHeight:
                                                                                                                                                          "1.5",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      id="caresidents"
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(0, 0, 0)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "19px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                              10.
                                                                                                                                                              DO
                                                                                                                                                              CALIFORNIA
                                                                                                                                                              RESIDENTS
                                                                                                                                                              HAVE
                                                                                                                                                              SPECIFIC
                                                                                                                                                              PRIVACY
                                                                                                                                                              RIGHTS?
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </strong>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <strong>
                                                                                                                                                          <em>
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                In
                                                                                                                                                                Short:
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                          </em>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <em>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              Yes,
                                                                                                                                                              if
                                                                                                                                                              you
                                                                                                                                                              are
                                                                                                                                                              a
                                                                                                                                                              resident
                                                                                                                                                              of
                                                                                                                                                              California,
                                                                                                                                                              you
                                                                                                                                                              are
                                                                                                                                                              granted
                                                                                                                                                              specific
                                                                                                                                                              rights
                                                                                                                                                              regarding
                                                                                                                                                              access
                                                                                                                                                              to
                                                                                                                                                              your
                                                                                                                                                              personal
                                                                                                                                                              information.
                                                                                                                                                            </span>
                                                                                                                                                          </em>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            California
                                                                                                                                                            Civil
                                                                                                                                                            Code
                                                                                                                                                            Section
                                                                                                                                                            1798.83,
                                                                                                                                                            also
                                                                                                                                                            known
                                                                                                                                                            as
                                                                                                                                                            the
                                                                                                                                                            “Shine
                                                                                                                                                            The
                                                                                                                                                            Light”
                                                                                                                                                            law,
                                                                                                                                                            permits
                                                                                                                                                            our
                                                                                                                                                            users
                                                                                                                                                            who
                                                                                                                                                            are
                                                                                                                                                            California
                                                                                                                                                            residents
                                                                                                                                                            to
                                                                                                                                                            request
                                                                                                                                                            and
                                                                                                                                                            obtain
                                                                                                                                                            from
                                                                                                                                                            us,
                                                                                                                                                            once
                                                                                                                                                            a
                                                                                                                                                            year
                                                                                                                                                            and
                                                                                                                                                            free
                                                                                                                                                            of
                                                                                                                                                            charge,
                                                                                                                                                            information
                                                                                                                                                            about
                                                                                                                                                            categories
                                                                                                                                                            of
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            (if
                                                                                                                                                            any)
                                                                                                                                                            we
                                                                                                                                                            disclosed
                                                                                                                                                            to
                                                                                                                                                            third
                                                                                                                                                            parties
                                                                                                                                                            for
                                                                                                                                                            direct
                                                                                                                                                            marketing
                                                                                                                                                            purposes
                                                                                                                                                            and
                                                                                                                                                            the
                                                                                                                                                            names
                                                                                                                                                            and
                                                                                                                                                            addresses
                                                                                                                                                            of
                                                                                                                                                            all
                                                                                                                                                            third
                                                                                                                                                            parties
                                                                                                                                                            with
                                                                                                                                                            which
                                                                                                                                                            we
                                                                                                                                                            shared
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            immediately
                                                                                                                                                            preceding
                                                                                                                                                            calendar
                                                                                                                                                            year.
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            a
                                                                                                                                                            California
                                                                                                                                                            resident
                                                                                                                                                            and
                                                                                                                                                            would
                                                                                                                                                            like
                                                                                                                                                            to
                                                                                                                                                            make
                                                                                                                                                            such
                                                                                                                                                            a
                                                                                                                                                            request,
                                                                                                                                                            please
                                                                                                                                                            submit
                                                                                                                                                            your
                                                                                                                                                            request
                                                                                                                                                            in
                                                                                                                                                            writing
                                                                                                                                                            to
                                                                                                                                                            us
                                                                                                                                                            using
                                                                                                                                                            the
                                                                                                                                                            contact
                                                                                                                                                            information
                                                                                                                                                            provided
                                                                                                                                                            below.
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                    <p
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          If
                                                                                                                                                          you
                                                                                                                                                          are
                                                                                                                                                          under
                                                                                                                                                          18
                                                                                                                                                          years
                                                                                                                                                          of
                                                                                                                                                          age,
                                                                                                                                                          reside
                                                                                                                                                          in
                                                                                                                                                          California,
                                                                                                                                                          and
                                                                                                                                                          have
                                                                                                                                                          a
                                                                                                                                                          registered
                                                                                                                                                          account
                                                                                                                                                          with{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize:
                                                                                                                                                                    "15px",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    the
                                                                                                                                                                    Website
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          ,
                                                                                                                                                          you
                                                                                                                                                          have
                                                                                                                                                          the
                                                                                                                                                          right
                                                                                                                                                          to
                                                                                                                                                          request
                                                                                                                                                          removal
                                                                                                                                                          of
                                                                                                                                                          unwanted
                                                                                                                                                          data
                                                                                                                                                          that
                                                                                                                                                          you
                                                                                                                                                          publicly
                                                                                                                                                          post
                                                                                                                                                          on
                                                                                                                                                          the{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize:
                                                                                                                                                                    "15px",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    Website
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          .
                                                                                                                                                          To
                                                                                                                                                          request
                                                                                                                                                          removal
                                                                                                                                                          of
                                                                                                                                                          such
                                                                                                                                                          data,
                                                                                                                                                          please
                                                                                                                                                          contact
                                                                                                                                                          us
                                                                                                                                                          using
                                                                                                                                                          the
                                                                                                                                                          contact
                                                                                                                                                          information
                                                                                                                                                          provided
                                                                                                                                                          below,
                                                                                                                                                          and
                                                                                                                                                          include
                                                                                                                                                          the
                                                                                                                                                          email
                                                                                                                                                          address
                                                                                                                                                          associated
                                                                                                                                                          with
                                                                                                                                                          your
                                                                                                                                                          account
                                                                                                                                                          and
                                                                                                                                                          a
                                                                                                                                                          statement
                                                                                                                                                          that
                                                                                                                                                          you
                                                                                                                                                          reside
                                                                                                                                                          in
                                                                                                                                                          California.
                                                                                                                                                          We
                                                                                                                                                          will
                                                                                                                                                          make
                                                                                                                                                          sure
                                                                                                                                                          the
                                                                                                                                                          data
                                                                                                                                                          is
                                                                                                                                                          not
                                                                                                                                                          publicly
                                                                                                                                                          displayed
                                                                                                                                                          on
                                                                                                                                                          the{" "}
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize:
                                                                                                                                                                    "15px",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    Website
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          ,
                                                                                                                                                          but
                                                                                                                                                          please
                                                                                                                                                          be
                                                                                                                                                          aware
                                                                                                                                                          that
                                                                                                                                                          the
                                                                                                                                                          data
                                                                                                                                                          may
                                                                                                                                                          not
                                                                                                                                                          be
                                                                                                                                                          completely
                                                                                                                                                          or
                                                                                                                                                          comprehensively
                                                                                                                                                          removed
                                                                                                                                                          from
                                                                                                                                                          all
                                                                                                                                                          our
                                                                                                                                                          systems
                                                                                                                                                          (e.g.
                                                                                                                                                          backups,
                                                                                                                                                          etc.).
                                                                                                                                                          &nbsp;
                                                                                                                                                        </span>
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </p>
                                                                                                                                                  </div>
                                                                                                                                                  <p
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                      lineHeight:
                                                                                                                                                        "1.5",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    id="policyupdates"
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                      lineHeight:
                                                                                                                                                        "1.5",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(0, 0, 0)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "19px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="heading_1">
                                                                                                                                                            11.
                                                                                                                                                            DO
                                                                                                                                                            WE
                                                                                                                                                            MAKE
                                                                                                                                                            UPDATES
                                                                                                                                                            TO
                                                                                                                                                            THIS
                                                                                                                                                            NOTICE?
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </strong>
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <strong>
                                                                                                                                                        <em>
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                              In
                                                                                                                                                              Short:
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                          &nbsp;
                                                                                                                                                        </em>
                                                                                                                                                        &nbsp;
                                                                                                                                                      </strong>
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <em>
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            Yes,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            update
                                                                                                                                                            this
                                                                                                                                                            notice
                                                                                                                                                            as
                                                                                                                                                            necessary
                                                                                                                                                            to
                                                                                                                                                            stay
                                                                                                                                                            compliant
                                                                                                                                                            with
                                                                                                                                                            relevant
                                                                                                                                                            laws.
                                                                                                                                                          </span>
                                                                                                                                                        </em>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        We
                                                                                                                                                        may
                                                                                                                                                        update
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          &nbsp;
                                                                                                                                                        </span>
                                                                                                                                                        from
                                                                                                                                                        time
                                                                                                                                                        to
                                                                                                                                                        time.
                                                                                                                                                        The
                                                                                                                                                        updated
                                                                                                                                                        version
                                                                                                                                                        will
                                                                                                                                                        be
                                                                                                                                                        indicated
                                                                                                                                                        by
                                                                                                                                                        an
                                                                                                                                                        updated
                                                                                                                                                        “Revised”
                                                                                                                                                        date
                                                                                                                                                        and
                                                                                                                                                        the
                                                                                                                                                        updated
                                                                                                                                                        version
                                                                                                                                                        will
                                                                                                                                                        be
                                                                                                                                                        effective
                                                                                                                                                        as
                                                                                                                                                        soon
                                                                                                                                                        as
                                                                                                                                                        it
                                                                                                                                                        is
                                                                                                                                                        accessible.
                                                                                                                                                        If
                                                                                                                                                        we
                                                                                                                                                        make
                                                                                                                                                        material
                                                                                                                                                        changes
                                                                                                                                                        to
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice,
                                                                                                                                                        we
                                                                                                                                                        may
                                                                                                                                                        notify
                                                                                                                                                        you
                                                                                                                                                        either
                                                                                                                                                        by
                                                                                                                                                        prominently
                                                                                                                                                        posting
                                                                                                                                                        a
                                                                                                                                                        notice
                                                                                                                                                        of
                                                                                                                                                        such
                                                                                                                                                        changes
                                                                                                                                                        or
                                                                                                                                                        by
                                                                                                                                                        directly
                                                                                                                                                        sending
                                                                                                                                                        you
                                                                                                                                                        a
                                                                                                                                                        notification.
                                                                                                                                                        We
                                                                                                                                                        encourage
                                                                                                                                                        you
                                                                                                                                                        to
                                                                                                                                                        review
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          &nbsp;
                                                                                                                                                        </span>
                                                                                                                                                        frequently
                                                                                                                                                        to
                                                                                                                                                        be
                                                                                                                                                        informed
                                                                                                                                                        of
                                                                                                                                                        how
                                                                                                                                                        we
                                                                                                                                                        are
                                                                                                                                                        protecting
                                                                                                                                                        your
                                                                                                                                                        information.
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                      lineHeight:
                                                                                                                                                        "1.5",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <br />
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    id="contact"
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(0, 0, 0)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <strong>
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "19px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="heading_1">
                                                                                                                                                            12.
                                                                                                                                                            HOW
                                                                                                                                                            CAN
                                                                                                                                                            YOU
                                                                                                                                                            CONTACT
                                                                                                                                                            US
                                                                                                                                                            ABOUT
                                                                                                                                                            THIS
                                                                                                                                                            NOTICE?
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </strong>
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        If
                                                                                                                                                        you
                                                                                                                                                        have
                                                                                                                                                        questions
                                                                                                                                                        or
                                                                                                                                                        comments
                                                                                                                                                        about
                                                                                                                                                        this
                                                                                                                                                        notice,
                                                                                                                                                        you
                                                                                                                                                        may{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        email
                                                                                                                                                        us
                                                                                                                                                        at{" "}
                                                                                                                                                        <bdt className="question">
                                                                                                                                                          hello@smashtaps.com
                                                                                                                                                        </bdt>
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                        or
                                                                                                                                                        by
                                                                                                                                                        post
                                                                                                                                                        to:
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </p>
                                                                                                                                                  <div>
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <bdt className="question">
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            SmashTaps
                                                                                                                                                            Pvt
                                                                                                                                                            Ltd
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                        &nbsp;
                                                                                                                                                      </bdt>
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </div>
                                                                                                                                                  <div>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="question">
                                                                                                                                                            409/12,
                                                                                                                                                            Senanayaka
                                                                                                                                                            Mawatha,
                                                                                                                                                            Kotte
                                                                                                                                                            Road,
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </div>
                                                                                                                                                  <div>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="question">
                                                                                                                                                            Pita
                                                                                                                                                            Kotte
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                          <bdt className="block-component" />{" "}
                                                                                                                                                          <bdt className="question">
                                                                                                                                                            10100
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </div>
                                                                                                                                                  <div>
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        fontSize:
                                                                                                                                                          "15px",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                          <bdt className="question">
                                                                                                                                                            Sri
                                                                                                                                                            Lanka
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="statement-end-if-in-editor">
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                  "15px",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </bdt>
                                                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize:
                                                                                                                                                              "15px",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                          <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                  </div>
                                                                                                                                                  <p
                                                                                                                                                    id="contact"
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                      lineHeight:
                                                                                                                                                        "1.5",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <br />
                                                                                                                                                  </p>
                                                                                                                                                  <p
                                                                                                                                                    id="contact"
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <strong>
                                                                                                                                                      <span data-custom-class="heading_1">
                                                                                                                                                        HOW
                                                                                                                                                        CAN
                                                                                                                                                        YOU
                                                                                                                                                        REVIEW,
                                                                                                                                                        UPDATE,
                                                                                                                                                        OR
                                                                                                                                                        DELETE
                                                                                                                                                        THE
                                                                                                                                                        DATA
                                                                                                                                                        WE
                                                                                                                                                        COLLECT
                                                                                                                                                        FROM
                                                                                                                                                        YOU?
                                                                                                                                                      </span>
                                                                                                                                                    </strong>
                                                                                                                                                  </p>
                                                                                                                                                  <div>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      Based
                                                                                                                                                      on
                                                                                                                                                      the
                                                                                                                                                      applicable
                                                                                                                                                      laws
                                                                                                                                                      of
                                                                                                                                                      your
                                                                                                                                                      country,
                                                                                                                                                      you
                                                                                                                                                      may
                                                                                                                                                      have
                                                                                                                                                      the
                                                                                                                                                      right
                                                                                                                                                      to
                                                                                                                                                      request
                                                                                                                                                      access
                                                                                                                                                      to
                                                                                                                                                      the
                                                                                                                                                      personal
                                                                                                                                                      information
                                                                                                                                                      we
                                                                                                                                                      collect
                                                                                                                                                      from
                                                                                                                                                      you,
                                                                                                                                                      change
                                                                                                                                                      that
                                                                                                                                                      information,
                                                                                                                                                      or
                                                                                                                                                      delete
                                                                                                                                                      it
                                                                                                                                                      in
                                                                                                                                                      some
                                                                                                                                                      circumstances.
                                                                                                                                                      To
                                                                                                                                                      request
                                                                                                                                                      to
                                                                                                                                                      review,
                                                                                                                                                      update,
                                                                                                                                                      or
                                                                                                                                                      delete
                                                                                                                                                      your
                                                                                                                                                      personal
                                                                                                                                                      information,
                                                                                                                                                      please{" "}
                                                                                                                                                      <bdt className="block-component" />
                                                                                                                                                      visit:{" "}
                                                                                                                                                      <bdt className="question">
                                                                                                                                                        <a
                                                                                                                                                          href="https://app.hellomolly.io"
                                                                                                                                                          target="_blank"
                                                                                                                                                          data-custom-class="link"
                                                                                                                                                        >
                                                                                                                                                          https://app.hellomolly.io
                                                                                                                                                        </a>
                                                                                                                                                      </bdt>
                                                                                                                                                      <bdt className="else-block" />
                                                                                                                                                      .
                                                                                                                                                      We
                                                                                                                                                      will
                                                                                                                                                      respond
                                                                                                                                                      to
                                                                                                                                                      your
                                                                                                                                                      request
                                                                                                                                                      within
                                                                                                                                                      30
                                                                                                                                                      days.
                                                                                                                                                    </span>
                                                                                                                                                  </div>
                                                                                                                                                  <style
                                                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                                                      __html:
                                                                                                                                                        "\n        ul {\n          list-style-type: square;\n        }\n        ul > li > ul {\n          list-style-type: circle;\n        }\n        ul > li > ul > li > ul {\n          list-style-type: square;\n        }\n        ol li {\n          font-family: Arial ;\n        }\n      ",
                                                                                                                                                    }}
                                                                                                                                                  />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "#595959",
                                                                                                                                                    fontSize:
                                                                                                                                                      "14px",
                                                                                                                                                    fontFamily:
                                                                                                                                                      "Arial",
                                                                                                                                                    paddingTop:
                                                                                                                                                      "16px",
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  This
                                                                                                                                                  privacy
                                                                                                                                                  policy
                                                                                                                                                  was
                                                                                                                                                  created
                                                                                                                                                  using{" "}
                                                                                                                                                  <a
                                                                                                                                                    style={{
                                                                                                                                                      color:
                                                                                                                                                        "rgb(48, 48, 241) !important",
                                                                                                                                                    }}
                                                                                                                                                    href="https://termly.io/products/privacy-policy-generator/?ftseo"
                                                                                                                                                  >
                                                                                                                                                    Termly’s
                                                                                                                                                    Privacy
                                                                                                                                                    Policy
                                                                                                                                                    Generator
                                                                                                                                                  </a>
                                                                                                                                                  .
                                                                                                                                                </div>
                                                                                                                                              </div>
                                                                                                                                            </div>
                                                                                                                                          </div>
                                                                                                                                        </div>
                                                                                                                                      </div>
                                                                                                                                    </div>
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                            </div>
                                                                                                                          </div>
                                                                                                                        </div>
                                                                                                                      </div>
                                                                                                                    </div>
                                                                                                                  </div>
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p>
        Hi there! You must be concerned about your privacy if you’re reading
        this message. Your time is valuable so we’ll keep it simple.
      </p>

      <ol>
        <li>
          We collect your email address and no other information from you.
        </li>
        <li>
          We’ll use this information by adding you to our list of interested
          users, send you occasional newsletters that include an opt-out link,
          and let you know when our service is ready to use.
        </li>
        <li>
          We promise not to transfer your information to a 3rd party service
          that are not directly required for us to perform business.
        </li>
        <li>
          Every email we send will include a link to opt out of any future
          communication. You can also contact hello@smashtaps.com directly if
          you wish for your data to be 100% deleted.
        </li>
      </ol>

      <p>
        That’s all for now! This policy may be changed in the future, but we
        realize that you’re paying us with your attention and it’s our goal to
        respect that transaction.
      </p> */}
    </div>
  </Layout>
)

export default PrivacyPage
